<template>
    <validation-observer ref="refForm">

        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >

            <b-row>
                <b-col md="12">
                    <b-card footer-tag="footer">

                        <b-row>

                            <b-col cols="12">
                                <b-alert variant="info" :show="true">
                                    <div class="alert-body">
                                        <ul class="mb-0">
                                            <li>
                                                Comprime y optimiza tus imágenes en 
                                                <b-link
                                                    class="alert-link"
                                                    href="https://tinypng.com/"
                                                    target="_blank"
                                                >
                                                    Tinify
                                                </b-link>
                                            </li>
                                            <li>
                                                Convierte tus imágenes a .webp en 
                                                <b-link
                                                    class="alert-link"
                                                    href="https://pixelied.com/convert/jpg-converter"
                                                    target="_blank"
                                                >
                                                    Pixelied
                                                </b-link>
                                            </li>
                                        </ul>
                                    </div>
                                </b-alert>
                            </b-col>

                            <!-- BANNER IMAGE -->
                            <b-col md="12">

                                <!-- PREVIEW BANNER IMG -->
                                <div id="banner-preview" v-if="formData.bannerUrl">
                                    <b-img
                                        fluid
                                        :src="formData.bannerUrl"
                                        thumbnail
                                        width="800"
                                        alt="image"
                                    />
                                </div>

                                <!-- IMG -->
                                <b-form-group
                                    label-for="bannerImage"
                                >
                                    <template #label>
                                        <strong>IMAGEN BANNER (Formato: webp)</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="imagen del banner"
                                    >
                                        <b-form-file
                                            id="bannerImage"
                                            v-model="formData.bannerImage"
                                            @change="onBannerFileChange"
                                            accept=".webp"
                                            ref="bannerImage"
                                            placeholder="Elija una imagen para el banner ..."
                                            :state="errors.length > 0 ? false : null"
                                            no-drop
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-button variant="outline-primary" class="mb-2" @click="removeBannerImage" v-if="formData.bannerUrl">Eliminar imagen del banner</b-button>
                            </b-col>

                            <!-- MAIN IMAGE -->
                            <b-col md="12">

                                <!-- PREVIEW MAIN IMG -->
                                <div id="main-preview" v-if="formData.mainUrl">
                                    <b-img
                                        fluid
                                        :src="formData.mainUrl"
                                        thumbnail
                                        width="500"
                                        alt="image"
                                    />
                                </div>

                                <!-- IMG -->
                                <b-form-group
                                    label-for="mainImage"
                                >
                                    <template #label>
                                        <strong>IMAGEN PRINCIPAL (Formato: webp)</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="imagen principal"
                                    >
                                        <b-form-file
                                            id="mainImage"
                                            v-model="formData.mainImage"
                                            @change="onMainFileChange"
                                            accept=".webp"
                                            ref="mainImage"
                                            placeholder="Elija una imagen principal ..."
                                            :state="errors.length > 0 ? false : null"
                                            no-drop
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-button variant="outline-primary" class="mb-2" @click="removeMainImage" v-if="formData.mainUrl">Eliminar imagen principal</b-button>
                            </b-col>

                            <!-- TITLE -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="title"
                                >
                                    <template #label>
                                        <strong>TÍTULO</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="título"
                                    >
                                        <b-form-input
                                            id="title"
                                            v-model="formData.title"
                                            placeholder="Ingrese el título ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- PROPER TYPE -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="properType"
                                >
                                    <template #label>
                                        <strong>TIPO DE PROPIEDAD</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="tipo de propiedad"
                                    >
                                        <v-select
                                            id="properType"
                                            placeholder="Seleccione un tipo de propiedad"
                                            v-model="formData.properType"
                                            :options="options.properTypes"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>	
                                </b-form-group>
                            </b-col>

                            <!-- CONDITION -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="condition"
                                >
                                    <template #label>
                                        <strong>CONDICIÓN</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="condición"
                                    >
                                        <v-select
                                            id="condition"
                                            placeholder="Seleccione una condición"
                                            v-model="formData.condition"
                                            :options="options.conditions"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>	
                                </b-form-group>
                            </b-col>

                            <!-- ADDRESS -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="address"
                                >
                                    <template #label>
                                        <strong>DIRECCIÓN</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="dirección"
                                    >
                                        <b-form-input
                                            id="address"
                                            v-model="formData.address"
                                            placeholder="Ingrese la dirección ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- YOUTUBE ID -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="youtube_id"
                                >
                                    <template #label>
                                        <strong>CÓDIGO DE YOUTUBE</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="código de youtube"
                                    >
                                        <b-form-input
                                            id="youtube_id"
                                            v-model="formData.youtube_id"
                                            placeholder="Ingrese el código de youtube ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- PRICE -->
                            <b-col cols="12" md="6">
                                <b-form-group
                                    label-for="price"
                                >
                                    <template #label>
                                        <strong>PRECIO</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="precio"
                                    >
                                        <b-form-input
                                            id="price"
                                            v-model="formData.price"
                                            placeholder="Ingrese el precio ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- BEDROOMS -->
                            <b-col cols="12" md="3">
                                <b-form-group
                                    label-for="bedrooms"
                                >
                                    <template #label>
                                        <strong># HABITACIONES</strong>
                                    </template>
                                    <b-form-input
                                        id="bedrooms"
                                        type="number"
                                        v-model="formData.bedrooms"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- GARAGE -->
                            <b-col cols="12" md="3">
                                <b-form-group
                                    label-for="garage"
                                >
                                    <template #label>
                                        <strong># COCHERAS</strong>
                                    </template>
                                    <b-form-input
                                        id="garage"
                                        type="number"
                                        v-model="formData.garage"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- SQUARE METER -->
                            <b-col cols="12" md="3">
                                <b-form-group
                                    label-for="square_meter"
                                >
                                    <template #label>
                                        <strong>METROS CUADRADOS</strong>
                                    </template>
                                    <b-form-input
                                        id="square_meter"
                                        type="number"
                                        v-model="formData.square_meter"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- BATHROOMS -->
                            <b-col cols="12" md="3">
                                <b-form-group
                                    label-for="bathrooms"
                                >
                                    <template #label>
                                        <strong># BAÑOS</strong>
                                    </template>
                                    <b-form-input
                                        id="bathrooms"
                                        type="number"
                                        v-model="formData.bathrooms"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- ELEVATOR -->
                            <b-col cols="12" md="3">
                                <b-form-group
                                    label-for="elevator"
                                >
                                    <template #label>
                                        <strong>¿TIENE ELEVADOR?</strong>
                                    </template>
                                    <v-select
                                        id="elevator"
                                        placeholder="Seleccione una opción"
                                        v-model="formData.elevator"
                                        :options="yesOrNoOptions"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- SOCIAL AREAS -->
                            <b-col cols="12" md="3">
                                <b-form-group
                                    label-for="social_areas"
                                >
                                    <template #label>
                                        <strong>¿TIENE ÁREAS SOCIALES?</strong>
                                    </template>
                                    <v-select
                                        id="social_areas"
                                        placeholder="Seleccione una opción"
                                        v-model="formData.social_areas"
                                        :options="yesOrNoOptions"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- STAIRS -->
                            <b-col cols="12" md="3">
                                <b-form-group
                                    label-for="stairs"
                                >
                                    <template #label>
                                        <strong>¿TIENE ESCALERAS?</strong>
                                    </template>
                                    <v-select
                                        id="stairs"
                                        placeholder="Seleccione una opción"
                                        v-model="formData.stairs"
                                        :options="yesOrNoOptions"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- GOOGLE MAPS -->
                            <b-col cols="12">
                                <b-form-group
                                    label-for="google_maps"
                                >
                                    <template #label>
                                        <strong>GOOGLE MAPS</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="google maps"
                                    >
                                        <b-form-textarea
                                            id="google_maps"
                                            v-model="formData.google_maps"
                                            class="char-textarea"
                                            placeholder="Google Maps ..."
                                            :state="errors.length > 0 ? false : null"
                                            rows="5"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- DESCRIPTION -->
                            <b-col cols="12">
                                <b-form-group
                                    label-for="description"
                                >
                                    <template #label>
                                        <strong>DESCRIPCIÓN</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="descripción"
                                    >
                                        <b-form-textarea
                                            id="description"
                                            v-model="formData.description"
                                            class="char-textarea"
                                            placeholder="Descripción ..."
                                            :state="errors.length > 0 ? false : null"
                                            rows="5"
                                        />
                                        <small class="textarea-counter-value float-right">
                                            <strong class="char-count">Caracteres: {{ formData.description ? formData.description.length : 0 }}</strong>
                                        </small>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>

                        <template #footer>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading 
                                text="AGREGAR" 
                                type="submit"
                                :processing="processing">
                            </b-button-loading>

                        </template>

                    </b-card>
                </b-col>
            </b-row>

        </b-form>

    </validation-observer>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import properStoreModule from '../properStoreModule';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import vSelect from 'vue-select';
    import router from '@/router';
    import store from '@/store';
    import axios from '@axios';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,

            required,
            vSelect
        },
        directives: {
            Ripple
        },
        setup() {

            // USE TOAST
            const toast = useToast();

            // REFS
            const processing = ref(false);
            const refForm = ref(null);
            const options = ref({});

            const bannerImage = ref(null);
            const mainImage = ref(null);

            const yesOrNoOptions = ref([
                { value: 'true', label: 'SÍ' },
                { value: 'false', label: 'NO' }
            ]);

            const formData = ref({
                bannerUrl: null,
                bannerImage: null,
                mainUrl: null,
                mainImage: null,
                title: null,
                properType: null,
                condition: null,
                address: null,
                youtube_id: null,
                price: null,
                bedrooms: null,
                garage: null,
                square_meter: null,
                bathrooms: null,
                elevator: null,
                social_areas: null,
                stairs: null,
                google_maps: null,
                description: null
            });

            const PROPER_APP_STORE_MODULE_NAME = 'app-proper';

			// REGISTER MODULE
			if (!store.hasModule(PROPER_APP_STORE_MODULE_NAME)) store.registerModule(PROPER_APP_STORE_MODULE_NAME, properStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(PROPER_APP_STORE_MODULE_NAME)) store.unregisterModule(PROPER_APP_STORE_MODULE_NAME);
			});

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( () => {
                getSelectFilters();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/propers/selects');
                
				options.value = data.data;
            };

            const onBannerFileChange = (e) => {
                const file = e.target.files[0];

                if (file) {
                    formData.value.bannerImage = file;
                    formData.value.bannerUrl = URL.createObjectURL(file);
                } else {
                    formData.value.bannerImage = null;
                    formData.value.bannerUrl = null;
                }
            };

            const onMainFileChange = (e) => {
                const file = e.target.files[0];

                if (file) {
                    formData.value.mainImage = file;
                    formData.value.mainUrl = URL.createObjectURL(file);
                } else {
                    formData.value.mainImage = null;
                    formData.value.mainUrl = null;
                }
            };

            const resetFormData = () => {
                formData.value = {
                    bannerUrl: null,
                    bannerImage: null,
                    mainUrl: null,
                    mainImage: null,
                    title: null,
                    properType: null,
                    condition: null,
                    address: null,
                    youtube_id: null,
                    price: null,
                    bedrooms: null,
                    garage: null,
                    square_meter: null,
                    bathrooms: null,
                    elevator: null,
                    social_areas: null,
                    stairs: null,
                    google_maps: null,
                    description: null
                }
            };

            const removeBannerImage = () => {
                formData.value.bannerUrl = null;
                formData.value.bannerImage = null;

                bannerImage.value.reset();
            };

            const removeMainImage = () => {
                formData.value.mainUrl = null;
                formData.value.mainImage = null;

                mainImage.value.reset();
            };

            const onSubmit = async () => {

                const isValid = await refForm.value.validate();

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                    
                    return;
                }

                processing.value = true;

                const {

                    bannerImage,
                    mainImage,
                    title,
                    properType,
                    condition,
                    address,
                    youtube_id,
                    price,
                    bedrooms,
                    garage,
                    square_meter,
                    bathrooms,
                    elevator,
                    social_areas,
                    stairs,
                    google_maps,
                    description

                } = formData.value;

                const payload = {
                    bannerImage,
                    mainImage,
                    title,
                    properType: properType.value,
                    condition: condition.value,
                    address,
                    youtube_id,
                    price,
                    bedrooms,
                    garage,
                    square_meter,
                    bathrooms,
                    elevator: elevator ? elevator.value : null,
                    social_areas: social_areas ? social_areas.value : null,
                    stairs: stairs ? stairs.value : null,
                    google_maps,
                    description
                }

                store.dispatch('app-proper/createProper', { payload })
                    .then( response => {

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        resetFormData();
                        refForm.value.reset();

                        setTimeout(() => {
                            router.push({ name: 'propers-edit', params: { slug: response.data.slug } });
                        }, 2000);

                    })
                    .catch( (err) => {
                        const message = err.response.data.message ? err.response.data.message : 'Error al agregar la propiedad.';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                        processing.value = false;
                    });

            };

            return {
                // DATA
                processing,
                formData,

                // REFS
                refForm,
                options,
                bannerImage,
                mainImage,
                yesOrNoOptions,

                // METHODS
                onBannerFileChange,
                onMainFileChange,
                removeBannerImage,
                removeMainImage,
                onSubmit
            }
        }
    }

</script>

<style lang="scss" scoped>

    #banner-preview,
    #main-preview {
        text-align: center;
        margin-bottom: 1rem;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>